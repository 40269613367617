<template>
  <v-container class="sync-configuration-page">
    <div v-if="items.length < 1 || isLoadingOnPage || (currentShop != null && currentShop.type != 'source' && currentActivePlan === null)">
      <data-preparation-overlay></data-preparation-overlay>
    </div>
    <div v-else>
      <BackToSettings />

      <product-sync-config-upgrade-dialog></product-sync-config-upgrade-dialog>
      <v-row>
        <v-col cols="12">
          <h1 class="text-h3">Product Settings</h1>
          <div class="pt-2">
            Manage how your products sync with your connected
            {{ partnerStoreLabel }}.
          </div>
          <div v-if="isDirty" class="action-bar mt-5">
            <v-btn
              large
              color="primary"
              class="float-right btn btn-primary"
              @click="saveConfigurations"
            >
              Save the Changes
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <v-row class="pt-5">
        <v-col cols="12">
          <v-tabs class="header-tabs" :class="currentShop.type" v-model="tab" background-color="transparent">
            <v-tab v-for="item in items" :key="item.name" :class="item.name.toLowerCase()" @click="clearCurrentConfig">
              {{ item.name }}
            </v-tab>
          </v-tabs>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="pt-0">
          <v-tabs-items v-model="tab">
            <v-tab-item v-for="item in items" :key="item.name">
              <v-card class="pa-5">
                <v-row>
                  <v-col cols="12" class="pt-0">
                    <v-card-text class="v-card-texts px-0 py-6" v-if="currentShop.type === 'destination'">
                      For support with the following, contact our friendly team via the in-app chat: <strong>Tag Mirror</strong> (syncs Source store deletion of tags), <br> sync of the <strong>Barcode</strong> field, disable <strong>Stock Sync</strong>, and broken <strong>SKU recovery</strong>.
                    </v-card-text>
                    <v-card-text v-else>
                      {{ item.description }}
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" class="pt-0"  v-if="currentShop.type === 'destination'">
                    <h5 class="v-card-sub-text py-4">Attributes to sync</h5>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="5">
                    <v-list class="pt-0">
                      <v-list-item
                        v-for="config in item.configs"
                        :key="config.label"
                        class="config-block"
                        @click="updateConfigDescription(config)"
                        :class="{ 'active-config': config.key === currentConfig.key }"
                      >
                        <v-switch
                          v-model="config.value"
                          inset
                          dense
                          color="blue darken-1"
                          :label="config.label"
                        >
                        </v-switch>
                      </v-list-item>
                    </v-list>
                  </v-col>
                  <v-col cols="7">
                    <v-card
                      outlined
                      color="grey lighten-4"
                      class="config-description-helper-content pa-5"
                    >
                      <v-card-text
                        v-if="currentConfigDescription != null"
                        v-html="currentConfigDescription"
                      ></v-card-text>
                      <v-card-text v-else class="text-center mt-3">
                        <v-icon class="mr-3">error_outline</v-icon>
                        Click on an attribute to see more details.
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <v-dialog v-model="showBeforeRouteChangeModal" width="500">
        <v-card light class="pa-5 pl-3 pr-3">
          <v-card-title>
            <h2>Unsaved Changes</h2>
          </v-card-title>
          <v-card-text>
            If you leave this page, any unsaved changes will be lost.
          </v-card-text>
          <v-card-actions>
            <v-row>
              <v-col cols="12">
                <v-btn
                  outlined
                  class="action-btn float-right leave-btn"
                  @click="leavePageNow()"
                >
                  Leave
                </v-btn>
                <v-btn
                  light
                  outlined
                  class="action-btn float-right mr-3"
                  @click="showBeforeRouteChangeModal = false"
                >
                  Cancel
                </v-btn>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import ProductSyncConfigUpgradeDialog from "@/views/product/components/ProductSyncConfigUpgradeDialog";
import BackToSettings from "@/views/settings/BackToSettings.vue";
import { mapState } from "vuex";
import {
  LOAD_CURRENT_CONFIGURATIONS,
  UPDATE_CURRENT_CONFIGURATIONS,
} from "@/store/actions.type";
import DataPreparationOverlay from "@/views/components/DataPreparationOverlay";

export default {
  name: "ProductConfigurationsPage",
  data: function() {
    return {
      tab: null,
      currentConfig: '',
      currentConfigDescription: null,
      items: [],
      existingConfigsStr: null,
      isLoadingOnPage: false,
      showBeforeRouteChangeModal: false,
      forceLeaving: false,
    };
  },
  created() {
    this.$store.dispatch(`shop/init`);
    this.isLoadingOnPage = true;
    this.$store
      .dispatch(`shop/loadCurrentShop`)
      .then((data) => {
        if (data.success === true) {
          this.isLoadingOnPage = false;
          if (
            this.currentShop != null &&
            this.currentShop.type != "source" &&
            (this.currentActivePlan === null ||
              typeof this.currentActivePlan === "undefined")
          ) {
            this.$store.dispatch("plan/getCurrentPlan");
          }
          this.currentConfigDescription = null;
          this.$store.dispatch(`configs/${LOAD_CURRENT_CONFIGURATIONS}`, {
            currentUserId: this.user.id,
          });

          if(this.currentShop && this.currentShop.platform === 'woocommerce' && this.currentShop.type === 'source') {
            this.$router.push({ name: 'DashboardPage' });
          }
        }
      })
      .catch(() => {
        this.isLoadingOnPage = false;
      });
  },
  components: {
    ProductSyncConfigUpgradeDialog,
    DataPreparationOverlay,
    BackToSettings,
  },
  computed: {
    ...mapState("configs", ["currentConfigs"]),
    ...mapState("shop", ["currentShop"]),
    ...mapState("plan", ["currentActivePlan"]),
    ...mapState("auth", ["user"]),
    partnerStoreLabel() {
      if (this.currentShop != null && this.currentShop.type != "source") {
        return "Source store";
      } else {
        return "Destination store";
      }
    },
    isDirty() {
      if (this.items != null || this.items.length > 0) {
        return this.isDirtyConfig();
      }
      return false;
    },
  },
  watch: {
    currentConfigs(newData) {
      if (newData != null) {
        let data = Object.assign({}, newData);
        this.existingConfigsStr = JSON.stringify(data);
        this.items = this.convertToUxConfigs(data);
      }
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.forceLeaving) {
      this.showBeforeRouteChangeModal = true;
      this.routeTo = to;
    } else {
      next();
    }
  },
  methods: {
    clearCurrentConfig() {
      this.currentConfig = '';
      this.currentConfigDescription = null;
    },
    leavePageNow() {
      if (this.routeTo != null) {
        this.forceLeaving = true;
        this.$router.push(this.routeTo);
      }
    },
    updateConfigDescription(config) {
      this.currentConfig = config;
      this.currentConfigDescription = config.description;
    },
    isDirtyConfig() {
      let isDirty = false;
      if (this.existingConfigsStr != null && this.items != null) {
        let newConfigs = JSON.stringify(this.currentConfigs);
        let oldConfigs = this.existingConfigsStr;
        if (newConfigs != oldConfigs) {
          isDirty = true;
        }
      }
      return isDirty;
    },
    flattenConfigData(formattedConfigs) {
      let newData = {};
      formattedConfigs.forEach((item) => {
        item.configs.forEach((config) => {
          newData[config.key] = config.value; // this will become an object!
        });
      });
      return newData;
    },
    saveConfigurations() {
      this.$store
        .dispatch(`configs/${UPDATE_CURRENT_CONFIGURATIONS}`, {
          userId: this.user.id,
          newConfigSettings: this.items,
        })
        .finally(() => {
          this.$store.dispatch(`shop/loadCurrentShop`);
        });
    },
    convertToUxConfigs(data) {
      const productConfigs = Object.assign(
        {},
        {
          name: "PRODUCT",
          description:
            "Switch on any product attributes that you would like to sync across all connected source stores.",
          configs: data.product,
        }
      );
      const variantConfigs = Object.assign(
        {},
        {
          name: "VARIANT",
          description:
            "Switch on any product attributes that you would like to sync across all connected source stores.",
          configs: data.variant,
        }
      );
      // const generalConfigs = Object.assign({}, {
      //     name: "GENERAL",
      //     description: "Switch on any product attributes that you would like to sync across all connected source stores.",
      //     configs: data.general,
      // });
      return [
        productConfigs,
        variantConfigs,
        // generalConfigs,
      ];
    },
  },
};
</script>
<style lang="scss" scoped>
.sync-configuration-page {
  position: relative;

  .header-tabs {
    background-color: transparent;

    .v-tab {
      padding: 10px 80px;
    }

    &.source {
      .variant {
        display: none;
      }
    }
  }

  .config-block {
    border: 1px solid #ccc;
    border-radius: 15px;
    margin: 0px 20px 15px 0px;
    padding: 0px 30px;

    .tooltip-icon {
      position: absolute;
      right: 15px;
    }
  }

  .config-description-helper-content {
    width: 100%;
    height: 100%;
  }

  .action-bar {
    position: absolute;
    overflow: hidden;
    top: 0;
    right: 15px;
  }

  .active-config {
    background: #f5f5f5;
  }
}

.action-btn {
  border-radius: 3px;
}

.leave-btn {
  background-color: #fcb058 !important;
}
.v-card-texts{
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  color: #000000;
  border-bottom: 1px solid #F5F5F5;
}

.v-card-sub-text {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #000000;
}
</style>
