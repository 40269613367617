<template>
    <v-overlay v-if="showUpgradeDialog && currentShop.type != 'source'">
        <v-card class="upgrade-plan-dialog pa-5" max-width="700" outlined light>
            <v-card-subtitle class="subtitle warning--text pb-0">
                <v-icon class="mr-3 warning--text">error_outline</v-icon>
                THIS IS AN ADD-ON FEATURE
            </v-card-subtitle>
            <v-card-title class="pa-0 primary--text">
                <v-card-text>
                    <h1 class="pl-1">Sync more than just inventory.</h1>
                </v-card-text>
            </v-card-title>
            <v-card-actions>
                <v-card-text class="pt-0 pb-0">
                    Our product sync add-on allows you to sync product and variant attributes such as title,
                    description, images, and much more.
                    <a class="learn-more" target="_blank"
                        href="https://help.syncio.co/en/articles/3704617-product-settings-add-on">Learn More.</a>
                </v-card-text>
            </v-card-actions>
            <v-card-actions>
                <v-card-text>
                    <div class="float-left" @click="redirectToBack()">Back</div>
                    <a class="float-right">
                        <v-btn class="primary white--text upgrade-plan" outlined @click="redirectToPlanUpgradePage()">
                            Upgrade Plan</v-btn>
                    </a>
                </v-card-text>
            </v-card-actions>
        </v-card>
    </v-overlay>
</template>
<script>
import { mapState } from "vuex";

export default {
    name: "ProductSyncConfigUpgradeDialog",
    computed: {
        ...mapState('shop', ['currentShop']),
        ...mapState('plan', ['currentActivePlan']),
        showUpgradeDialog() {
            let show = true;
            if (this.currentActivePlan != null) {
                this.currentActivePlan.active_addons.forEach((addon) => {
                    if (addon.module_id === 2) {
                        show = false;
                    }
                });
            }
            return show;
        }
    },
    methods: {
        redirectToBack() {
            this.$router.go(-1);
        },
        redirectToPlanUpgradePage() {
            this.$router.replace({
                name: "PlanSelectionPage"
            });
        },
    }
}
</script>
<style lang="scss" scoped>
.upgrade-plan-dialog {
    margin: auto;

    .subtitle {
        font-weight: 700;
    }

    .learn-more {
        color: #1E88E5;
        font-weight: 600;
    }

    .upgrade-plan {
        padding: 10px 40px;
    }
}
</style>